<script>
import {displayName} from './composer';
import ResourceUrl from '../layout/component/ResourceUrl.svelte';
import ResourceImage from '../layout/component/ResourceImage.svelte';
import YouTubeVideo from '../layout/component/YouTubeVideo.svelte';
import SectionName from '../layout/component/SectionName.svelte';
import SoundCloudTrack from '../layout/component/SoundCloudTrack.svelte';

export let composer;

/**
 * To determine if the section should be rendered
 *
 * @returns {*}
 */
const moreArchives = () => composer.videos.length
        || composer.recordings.length
        || composer.documents.length
        || composer.images.length;
</script>

{#if moreArchives()}
    <div class="container mx-auto mt-8 p-4 bg-white rounded-lg mx-4 md:max-w-6xl">
        <h2 class="text-3xl font-bold">Recursos de {displayName(composer)}</h2>

        {#if composer.videos.length}
            <SectionName name="Videos" />

            {#each composer.videos as video}
                <YouTubeVideo name={video.name} url={video.url}
                              description={video.description || ''} />
            {/each}
        {/if}

        {#if composer.recordings.length}
            <SoundCloudTrack recordings={composer.recordings} />
        {/if}

        {#if composer.documents.length}
            <ResourceUrl name="Documentos" resources={composer.documents} />
        {/if}

        {#if composer.images.length}
            <ResourceImage name="Fotos" resources={composer.images} />
        {/if}
    </div>
{/if}