<script>
import Resources from './Resources.svelte';
import WorkList from '../work/List.svelte';
import BackLink from '../layout/component/BackLink.svelte';
import Loading from '../layout/component/Loading.svelte';
import {displayName, displayPhoto} from './composer';

import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSER} from './graphql';

export let params = {}

const id = params.id;
const client = getClient();
const composer = query(client, {query: GET_COMPOSER, variables: {id}});
</script>

{#await $composer}
    <Loading />
{:then {data: {composer}}}
    <div class="container overflow-hidden mx-auto p-4
                bg-white rounded-lg mx-4 md:max-w-6xl">
        <BackLink />

        <div class="pt-4">
            <h1 class="text-2xl font-bold">{displayName(composer)}</h1>
        </div>

        <div class="pt-4">
            <div class="w-32 sm:w-48 md:w-64 ml-4 md:ml-8 mb-2 md:mb-4 float-right shadow-lg">
                <img class="rounded-lg" src={displayPhoto(composer.photo)} alt={displayName(composer)}/>
            </div>

            <div class="ck-content">
                {@html composer.biography || 'Pendiente'}
            </div>
        </div>
    </div>

    {#if composer.works.length}
        <div class="container mx-auto p-4 bg-white rounded-lg mx-4 mt-8 md:max-w-6xl">
            <WorkList {composer}/>
        </div>
    {/if}

    <Resources {composer} />

    <span class="hidden">{document.title = displayName(composer) + ' - Compositor Hondureño'}</span>
{:catch error}
    Error: {error}
{/await}