<script>
import WorkList from '../work/List.svelte';
import BackLink from '../layout/component/BackLink.svelte';
import NoResource from './NoResource.svelte';

import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSER} from '../composer/graphql';
import {displayName, displayPhoto} from '../composer/composer';
import Loading from '../layout/component/Loading.svelte';

export let params = {}

const id = params.id;
const client = getClient();
const composer = query(client, {query: GET_COMPOSER, variables: {id}});
</script>

{#await $composer}
    <Loading />
{:then {data: {composer}}}
    <div class="container overflow-hidden mx-auto p-4
                bg-white rounded-lg mx-4">
        <BackLink />

        {#if composer.works.length}
            <div class="pt-4">
                <h1 class="text-center bold text-2xl md:text-3xl">Obras de {displayName(composer)}</h1>
            </div>

            <WorkList {composer} title={false}/>
        {:else}
            <NoResource name={displayName(composer)} resource="obras" />
        {/if}
    </div>
{:catch error}
    Error: {error}
{/await}