<script>
import NavBar from "./component/NavBar.svelte";

const items = [
    {
        link: '/',
        label: 'Inicio'
    }, {
        link: '/compositores',
        label: 'Compositores'
    }
];
</script>

<NavBar {items}/>

<div class="m-4 sm:m-6 lg:m-8">
    <slot />
</div>