import moment from 'moment'
import {STD_DATE} from '../config/const';

export const MAX_SHORT_BIO_LEN = 255;

/**
 * To display the name of a composer
 *
 * @param c
 * @returns {*}
 */
const displayName = c => {
    let name = '';

    if (c.first_name) name += c.first_name + ' ';
    if (c.second_name) name += c.second_name + ' ';
    if (c.first_surname) name += c.first_surname + ' ';
    if (c.second_surname) name += c.second_surname + ' ';

    return name.trim();
}

/**
 * To display the birt date of a composer and death if known
 *
 * @param birth
 * @param death
 */
const displayDates = (birth, death) => {
    let date = '';

    if (birth) {
        date = moment(birth).format(STD_DATE)
    }

    if (death) {
        date += ' - ' + moment(death).format(STD_DATE);
    }

    return date;
}

/**
 * To display the photo of the composer or a default if one is not available
 *
 * @param photo
 * @returns {*}
 */
const displayPhoto = photo => photo && photo.url ? photo.url : '/img/svg/decision.svg';

export {
    displayName,
    displayDates,
    displayPhoto,
}