<script>
import Page from './layout/Page.svelte';
import Router from 'svelte-spa-router';
import {routes} from './config/routes';
import Tailwindcss from './layout/Tailwindcss.svelte';

import ApolloClient from 'apollo-boost';
import {setClient} from 'svelte-apollo';
import {GRAPHQL_URL} from './config/const';

const client = new ApolloClient({uri: GRAPHQL_URL});
setClient(client);
</script>

<Page>
    <Router {routes}/>
</Page>

<Tailwindcss/>