import {gql} from 'apollo-boost';

export const GET_COMPOSERS = gql`
    query composers {
        composers(where: {published: 1},sort:"first_name:asc") {
            id
            first_name
            second_name
            first_surname
            second_surname 
            birth
            death
            intro
            photo {
                url
            }
            works {
                scores {
                    id
                }
                videos {
                    id
                }
                recordings {
                    id
                }
            }
            videos {
                id
            }
            images {
                id
            }
            recordings {
                id
            }
            videos {
                id
            }
            documents {
                id
            }
        }
    }
`;

export const GET_COMPOSER = gql`
    query composer($id: ID!) {
        composer(id: $id) {
            id
            first_name
            second_name
            first_surname
            second_surname
            biography
            photo {
                url
            }
            works(sort: "name:asc") {
                id
                name
                description
                scores(sort: "order_by:asc,name:asc") {
                    id
                    name
                    url
                }
                
                videos(sort: "name:asc") {
                    id
                    name
                    url
                    description
                }
                
                recordings(sort: "name:asc") {
                    id
                    name
                    track
                    description
                }
            }
            videos(sort: "name:asc") {
                id
                name
                url
                description
            }
            images(sort: "name:asc") {
                id
                name
                image {
                    url
                }
                description
            }
            recordings(sort: "name:asc") {
                id
                track
                name
                description
               
            }
            documents(sort: "name:asc") {
                id
                name   
                url 
                description
            }
        }
    }
`;
