<script>
import YouTubeVideo from "../layout/component/YouTubeVideo.svelte";
import Loading from '../layout/component/Loading.svelte';
import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSERS} from "../composer/graphql";
import {GET_INICIO} from './graphql';

const client = getClient();
const inicio = query(client, {query: GET_INICIO});

// Preload the composers list
const composers = query(client, {query: GET_COMPOSERS})
</script>

{#await $composers}
    <span></span>
{:then {data: {composers}}}
    <span></span>
{/await}

{#await $inicio}
    <Loading />
{:then {data: {inicio}}}
    <div class="container bg-white rounded-lg overflow-hidden
                p-4 md:p-6 m-auto md:max-w-6xl">
        <h1 class="text-2xl md:text-3xl lg:text-center font-bold mb-4">{inicio.title}</h1>

        {#if inicio.image && inicio.image.url}
            <div class="mb-4 shadow-2xl">
                    <img class="rounded-lg w-full md:max-w-3xl lg:max-w-xl lg:float-left lg:mr-6"
                     src={inicio.image.url} alt="Imagen de la Página Inicial">
            </div>
        {/if}

        {#if inicio.url}
            <div class="sm:float-right">
                <YouTubeVideo url={inicio.url} />
            </div>
        {/if}

        <div class="ck-content">
            {@html inicio.content}
        </div>
    </div>
{:catch error}
    Error: {error}
{/await}