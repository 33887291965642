<script>
import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSERS} from './composer/graphql';
const client = getClient();
const composers = query(client, {query: GET_COMPOSERS});
import Loading from './layout/component/Loading.svelte';
/**
 * To generate the url part of the sitemap
 *
 * @param path
 * @returns {string}
 */
const url = path => {
    return '<url>\n'
        + '\t<loc>'+ path + '</loc>\n'
        + '\t<changefreq>weekly</changefreq>\n'
        + '\t<priority>0.5</priority>\n'
        +  '</url>\n';
}

/**
* To check if the user has videos
*/
const hasVideos = composer => {
    let hasVideos = false;

    if (composer.videos.length) {
        hasVideos = true;
    }

    for (let w = 0; w < composer.works.length && ! hasVideos; ++w) {
        if (composer.works[w].videos.length) {
            hasVideos = true;
            break;
        }
    }

    return hasVideos;
}

/**
* To check if the user has recordings
*/
const hasRecordings = composer => {
    let hasRecordings = false;

    if (composer.recordings.length) {
        hasRecordings = true;
    }

    for (let w = 0; w < composer.works.length && ! hasRecordings; ++w) {
        if (composer.works[w].recordings.length) {
            hasRecordings = true;
            break;
        }
    }

    return hasRecordings;
}

/**
* To check if the user has scores
*/
const hasScores = composer => {
    let hasScores = false;

    for (let w = 0; w < composer.works.length && ! hasScores; ++w) {
        if (composer.works[w].scores.length) {
            hasScores = true;
            break;
        }
    }

    return hasScores;
}

/**
 * To generate the contents of the /sitemap.xml
 *
 * Implemented:
 * 1. inicio
 * 2. compositores
 * 3. compositor
 *
 * @param composers
 * @returns {string}
 */
const sitemap = composers => {
    const site = 'https://www.seyer-reivaj.com/';
    const stats = {
        composer: 0,
        works: 0,
        videos: 0,
        scores: 0,
        recordings: 0,
        composers: 1,
        inicio: 1,
    }

    let xml = '<?xml version="1.0" encoding="UTF-8"?>\n'
            + '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

    composers.forEach((composer) => {
        xml += url(site + '#/compositor/' + composer.id);
        stats.composers++;
    });

    composers.forEach((composer) => {
        if (composer.works.length) {
            xml += url(site + '#/compositor/' + composer.id + '/obras');
            stats.works++;
        }
    });

    composers.forEach((composer) => {
        if (hasRecordings(composer)) {
            xml += url(site + '#/compositor/' + composer.id + '/grabaciones');
            stats.recordings++;
        }
    });

    composers.forEach((composer) => {
        if (hasVideos(composer)) {
            xml += url(site + '#/compositor/' + composer.id + '/videos');
            stats.videos++;
        }
    });

    composers.forEach((composer) => {
        if (hasScores(composer)) {
            xml += url(site + '#/compositor/' + composer.id + '/partituras');
            stats.scores++;
        }
    });

    xml += url(site + '#/compositores');
        +  url(site + '#/inicio');

    xml += '</urlset>';

    console.log(stats);

    return xml;
}

/**
 * To display the copie to clipboard alert
 */
const toClipboard = () => {
    const text = document.createElement('textarea');
    document.body.appendChild(text);
    text.textContent = document.getElementById('sitemap').innerText;
    text.select();
    document.execCommand('copy');
    text.remove();

    const copiedAlert = document.getElementById('copied-alert');
    copiedAlert.classList.remove('hidden');

    setTimeout(() => copiedAlert.classList.add('hidden'), 3000)
}
</script>

{#await $composers}
    <Loading />
{:then {data: {composers}}}
    <div class="bg-white p-4">
        <a href="#/" on:click|preventDefault={toClipboard}
           class="bg-blue-700 text-white py-2 px-4 rounded-lg">Copy Sitemap</a>
        <span id="copied-alert" class="text-sm text-gray-600 italic hidden">copied to clipboard</span>
        <pre id="sitemap" class="mt-4">
            {sitemap(composers)}
        </pre>
    </div>
{/await}