<script>
    export let composer;
    import {push} from 'svelte-spa-router';
    import {displayName} from '../composer/composer';
    import SvgIcon from '../layout/component/SvgIcon.svelte';
    const composerName = displayName(composer);

    /**
     * To retriever the composer score count
     */
    const scores = composer => {
        let count = 0;

        if (composer.works.length) {
            composer.works.forEach(work =>  count += work.scores.length);
        }

        return count;
    }

    /**
     * To retrieve the composer video count
     *
     * Videos of the composer or his works
     *
     * @param composer
     * @returns {*}
     */
    const videos = composer => {
        let count = composer.videos.length;

        if (composer.works.length) {
            composer.works.forEach(work => count += work.videos.length);
        }

        return count;
    }

    /**
     * To retrieve the composer video count
     *
     * Videos of the composer or his works
     *
     * @param composer
     * @returns {*}
     */
    const recordings = composer => {
        let count = composer.recordings.length;

        if (composer.works.length) {
            composer.works.forEach(work => count += work.recordings.length);
        }

        return count;
    }

    const setTitle = (composer, name, count) => {
        let title = '';
        if (count) {
            title = 'Tenemos ' + count + ' ';
        } else {
            title = 'Aún no tenemos ';
        }

        title += name + ' de ' + composerName;

        return title;
    }

    /**
     * To handle clicking on an asset icon
     *
     * If the composer has any assets a link to the route is return or false otherwise.
     *
     * @param id
     * @param route
     * @param count
     * @returns {*}
     */
    const onClick = (id, route, count) => count ? push('/compositor/' + id + '/' + route, {replace: true}) : false;

    const workCount = composer.works.length;
    const videoCount = videos(composer);
    const scoreCount = scores(composer);
    const recordingCount = recordings(composer);
    const imageCount = composer.images.length;
</script>

<div class="grid grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2  md:grid-cols-3 md:gap-2 lg:flex mt-4">
    <div class="flex mr-2 sm:mr-4 md:mr-8 text-sm cursor-pointer"
         title={setTitle(composer, 'obras', workCount)}
         on:click={() => onClick(composer.id, 'obras', workCount)}>
        <SvgIcon name="work" />
        <span>{composer.works.length || '0'} Obras</span>
    </div>

    <div class="flex mr-2 sm:mr-4 md:mr-8 text-sm cursor-pointer"
         title={setTitle(composer, 'videos', videoCount)}
         on:click={() => onClick(composer.id, 'videos', videoCount)}>
        <SvgIcon name="video" />
        <span>{videoCount || '0'} Videos</span>
    </div>

    <div class="flex mr-2 sm:mr-4 md:mr-8 text-sm cursor-pointer"
         title={setTitle(composer, 'grabaciones', recordingCount)}
         on:click={() => onClick(composer.id, 'grabaciones', recordingCount)}>
        <SvgIcon name="recording"/>
        <span>{recordingCount || '0'} Grabaciones</span>
    </div>

    <div class="flex mr-2 sm:mr-4 md:mr-8 text-sm cursor-pointer"
         title={setTitle(composer, 'partituras', scoreCount)}
         on:click={() => onClick(composer.id, 'partituras', scoreCount)}>
        <SvgIcon name="score"/>
        <span>{scoreCount || '0'} Partituras</span>
    </div>

    <div class="flex mr-2 sm:mr-4 md:mr-8 text-sm cursor-pointer"
         title={setTitle(composer, 'fotos', imageCount)}
         on:click={() => onClick(composer.id, 'fotos', imageCount)}>
        <SvgIcon name="photo" />
        <span>{composer.images.length || '0'} Fotos</span>
    </div>
</div>
