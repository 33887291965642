<script>
export let recordings = [];

export let color = '#ff5500';
export let autoPlay = 'false';
export let hideRelated = 'false';
export let showComments = 'false';
export let showUser = 'false';
export let showReposts = 'false';
export let showTeaser = 'false';
export let visual = 'false';

const src = track => {
    return 'https://w.soundcloud.com/player/?'
         + 'url=https://api.soundcloud.com/tracks/' + track + '&'
         + 'color=' + encodeURIComponent(color) + '&'
         + 'auto_play=' + autoPlay + '&'
         + 'hide_related=' + hideRelated + '&'
         + 'show_comments=' + showComments + '&'
         + 'show_user=' + showUser + '&'
         + 'show_reposts=' + showReposts + '&'
         + 'show_teaser=' + showTeaser + '&'
         + 'visual=' + visual;
}
</script>

<div class="flex flex-wrap items-stretch">
    {#if recordings.length}
        {#each recordings as recording}
            {#if recording.track}
                <div class="w-full p-4">
                    <iframe class="w-full" scrolling="no" frameborder="no" title={recording.name}
                            src={src(recording.track)}></iframe>

                    <h2 class="py-4 text-blue-800">{recording.name}</h2>
                    <div>{@html recording.description || ''}</div>
                </div>
            {/if}
        {/each}
    {/if}
</div>