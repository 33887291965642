import {gql} from 'apollo-boost';

export const GET_INICIO = gql`
    query inicio {
        inicio {
            title
            image {
                url
            }
            url
            content
        }
    }
`;
