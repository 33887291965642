<script>
import {MAX_SHORT_BIO_LEN} from './composer'

export let biography;

/**
 * To truncate the biography if necessary
 */
const truncate = bio => isShort(bio) ? bio.substring(0, MAX_SHORT_BIO_LEN) + '...' : bio;

/**
 * To check if the bio is short enough to truncate
 *
 * @param bio
 * @returns {boolean}
 */
const isShort = bio => bio && bio.length > MAX_SHORT_BIO_LEN;
</script>

<p class="mt-3 pr-20 text-gray-700 text-sm">
    {@html truncate(biography)}
</p>