<script>
export let name;
export let viewBox = '0 0 20 20';
</script>

{#if name === 'photo'}
    <svg class="w-5 h-5 mr-1 fill-current text-yellow-600" viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        <path d="M0 6c0-1.1.9-2 2-2h3l2-2h6l2 2h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6zm10 10a5 5 0 1 0 0-10 5 5 0 0 0 0 10zm0-2a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
    </svg>
{:else if name ==='score'}
    <svg class="w-5 h-5 mr-1 fill-current text-orange-800" viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2.5V0L6 2v12.17A3 3 0 0 0 5 14H3a3 3 0 0 0 0 6h2a3 3 0 0 0 3-3V5.71L18 4.3v7.88a3 3 0 0 0-1-.17h-2a3 3 0 0 0 0 6h2a3 3 0 0 0 3-3V2.5z"/>
    </svg>
{:else if name === 'recording'}
    <svg class="w-6 h-6 mr-1 fill-current text-green-600" viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        <path d="M5 7H1v6h4l5 5V2L5 7zm11.36 9.36l-1.41-1.41a6.98 6.98 0 0 0 0-9.9l1.41-1.41a8.97 8.97 0 0 1 0 12.72zm-2.82-2.82l-1.42-1.42a3 3 0 0 0 0-4.24l1.42-1.42a4.98 4.98 0 0 1 0 7.08z"/>
    </svg>
{:else if name === 'video'}
    <svg class="w-5 h-5 mr-1 fill-current text-indigo-600" viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm6 0v12h8V4H6zM2 5v2h2V5H2zm0 4v2h2V9H2zm0 4v2h2v-2H2zm14-8v2h2V5h-2zm0 4v2h2V9h-2zm0 4v2h2v-2h-2zM8 7l5 3-5 3V7z"/>
    </svg>
{:else if name === 'work'}
    <svg class="w-5 h-5 mr-1 fill-current text-red-600" viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
        <path d="M16 17a3 3 0 0 1-3 3h-2a3 3 0 0 1 0-6h2a3 3 0 0 1 1 .17V1l6-1v4l-4 .67V17zM0 3h12v2H0V3zm0 4h12v2H0V7zm0 4h12v2H0v-2zm0 4h6v2H0v-2z"/>
    </svg>
{/if}