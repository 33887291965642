<script>
import BackLink from '../layout/component/BackLink.svelte';
import NoResource from './NoResource.svelte';
import Loading from '../layout/component/Loading.svelte';
import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSER} from '../composer/graphql';
import {displayName, displayPhoto} from '../composer/composer';
import SoundCloudTrack from "../layout/component/SoundCloudTrack.svelte";
import WorkName from  '../work/Name.svelte';

export let params = {}

const id = params.id;
const client = getClient();
const composer = query(client, {query: GET_COMPOSER, variables: {id}});

/**
 * To print a subtitle but only if
 * @param composer
 * @returns {*|number}
 */
const isSubtitle = composer => composer.works.length;
</script>

{#await $composer}
    <Loading />
{:then {data: {composer}}}
    <div class="container overflow-hidden mx-auto p-4
                bg-white rounded-lg mx-4">
        <BackLink />

        {#if composer.recordings || composer.works.recordings}
            {#if composer.works.length}
                <div class="pt-4">
                    <h1 class="text-center bold text-2xl md:text-3xl">Grabaciones de las Obras de {displayName(composer)}</h1>
                </div>

                {#each composer.works as work}
                    {#if work.recordings.length}
                        <WorkName name={work.name} />
                        <SoundCloudTrack recordings={work.recordings}/>
                    {/if}
                {/each}
            {/if}

            {#if composer.recordings.length}
                {#if isSubtitle(composer)}
                    <h2 class="bold text-1xl md:text-2xl mt-4">Otras Grabaciones de {displayName(composer)}</h2>
                    <hr>
                {:else}
                    <h1 class="text-center bold text-2xl md:text-3xl">Grabaciones de {displayName(composer)}</h1>
                {/if}

                <div class="flex flex-wrap">
                    <SoundCloudTrack recordings={composer.recordings}/>
                </div>
            {/if}
        {:else}
            <NoResource name={displayName(composer)} resource="grabaciones" />
        {/if}
    </div>
{:catch error}
    Error: {error}
{/await}