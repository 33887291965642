import Inicio from '../page/Inicio.svelte';
import ComposerList from '../composer/List.svelte';
import ComposerDetails from '../composer/Details.svelte';
import Sitemap from '../Sitemap.svelte';
import {IS_PRODUCTION} from './const';
import ComposerVideos from '../resource/Video.svelte';
import ComposerWorks from '../resource/Work.svelte';
import ComposerScores from '../resource/Score.svelte';
import ComposerImages from '../resource/Image.svelte';
import ComposerRecordings from '../resource/Recording.svelte';

const routes = {
    '/': Inicio,
    '/inicio': Inicio,
    '/compositores': ComposerList,
    '/compositor/:id': ComposerDetails,
    '/compositor/:id/obras': ComposerWorks,
    '/compositor/:id/videos': ComposerVideos,
    '/compositor/:id/partituras': ComposerScores,
    '/compositor/:id/fotos': ComposerImages,
    '/compositor/:id/grabaciones': ComposerRecordings,
}

// This route is only available to generate a new sitemap during development.
if (! IS_PRODUCTION) {
    routes['/sitemap'] = Sitemap;
}

export {routes}