<script>
import YouTubeVideo from '../layout/component/YouTubeVideo.svelte';
import ResourceUrl from '../layout/component/ResourceUrl.svelte';
import WorkName from './Name.svelte';
import SectionName from '../layout/component/SectionName.svelte';
import {displayName, displayPhoto} from '../composer/composer';
import SoundCloudTrack from '../layout/component/SoundCloudTrack.svelte';

export let title = true;
export let composer;

const composerName = displayName(composer);
</script>

{#if title}
    <h2 class="text-2xl font-bold">Obras de {composerName}</h2>
{/if}

{#each composer.works as work}
    <WorkName name={work.name} />

    {#if work.description}
        <div class="pl-2 pb-4">{@html work.description}</div>
    {/if}

    <div class="pl-2">
        {#if work.videos.length}
            <SectionName name='Videos' />

            {#each work.videos as video}
                <YouTubeVideo name={video.name} url={video.url}
                              description={video.description || ''} />
            {/each}
        {/if}

        {#if work.scores.length}
            <SectionName name='Partituras' />

            <div class="flex flex-wrap items-stretch p-4">
                {#each work.scores as score}
                    <a class="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 text-center pb-4 py-4"
                       href={score.url} target={score.name}>
                        <img class="w-8 mx-auto" src="/img/svg/pdf.svg" alt={score.name}/>
                        <span class="pt-1 pl-2">{score.name}</span>
                    </a>
                {/each}
            </div>
        {/if}

        {#if work.recordings.length}
           <SectionName name='Grabaciones' />
            <SoundCloudTrack recordings={work.recordings} />
        {/if}
    </div>
{/each}