<script>
import BackLink from '../layout/component/BackLink.svelte';
import NoResource from './NoResource.svelte';
import WorkName from '../work/Name.svelte';
import Loading from '../layout/component/Loading.svelte';
import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSER} from '../composer/graphql';
import {displayName, displayPhoto} from '../composer/composer';

export let params = {}

const id = params.id;
const client = getClient();
const composer = query(client, {query: GET_COMPOSER, variables: {id}});

function hasScores(composer) {
    let hasScores = false;

    if (composer.works.length) {
        for (let i = 0; i < composer.works.length; ++i) {
            if (composer.works[i].scores.length) {
                hasScores = true;
                break;
            }
        }
    }

    return hasScores;
}
</script>

{#await $composer}
    <Loading />
{:then {data: {composer}}}
    <div class="container overflow-hidden mx-auto p-4
                bg-white rounded-lg mx-4">
        <BackLink />

        <div class="pt-4">
            <h1 class="text-center bold text-2xl md:text-3xl">Partituras de {displayName(composer)}</h1>
        </div>

        {#if hasScores(composer)}
            {#each composer.works as work}
                {#if work.scores.length}
                    <WorkName name={work.name} />

                    <div class="flex flex-wrap">
                        {#each work.scores as score}
                            <a class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 text-center mb-4 align-top"
                               href={score.url} target={score.name}>
                                <img class="w-16 mx-auto" src="/img/svg/pdf.svg" alt={score.name}/>
                                <span class="">{score.name}</span>
                            </a>
                        {/each}
                    </div>
                {/if}
            {/each}
        {:else}
            <NoResource name={displayName(composer)} resource="obras" />
        {/if}
    </div>
{:catch error}
    Error: {error}
{/await}