<script>
import {link} from 'svelte-spa-router';

export let url;
export let name;
</script>

<div class="absolute right-0 top-0 text-gray-700 text-sm cursor-pointer" title="Lea más acerca de {name}">
    <a href={url} use:link title={name}>
        <svg class="w-5 h-5 mr-1 fill-current text-pink-600" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"><path
                d="M16.172 9l-6.071-6.071 1.414-1.414L20 10l-.707.707-7.778 7.778-1.414-1.414L16.172 11H0V9z"/></svg>
    </a>
</div>