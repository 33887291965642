<script>
import {link} from 'svelte-spa-router';
export let items = [];

let isOpen = false;
</script>

<div>
<header class="sm:flex sm:justify-between sm:items-center text-white bg-blue-700">
    <div class="flex justify-between items-center px-4 py-2 sm:py-0">
        <a href="/" class="relative h-12" title="Antología de Compositores Hondureños">
            <img class="absolute w-10 mt-0" src="/img/logo.png" alt="Escudo de Honduras"/>
            <div class="ml-12 mt-0 sm:mt-3 font-bold">Antología de <br class="sm:hidden">Compositores Hondureños</div>
        </a>

        <div class="sm:hidden">
            <button class="focus:outline-none" on:click={() => isOpen = !isOpen}>
                    <svg class="fill-current h-4 w-4 text-blue-100 text-bold" viewBox="0 0 20 20"><title>Menu</title>
                        {#if isOpen}
                            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"/>
                        {:else}
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                        {/if}
                    </svg>
            </button>
        </div>
    </div>
    
    <div class="sm:flex text-right {isOpen ? 'block' : 'hidden'} sm:block">
        {#each items as item}
            <span class="block px-4 py-1 sm:py-4 font-semibold hover:bg-blue-600">
                <a href="{item.link}" use:link>{item.label}</a>
            </span>
        {/each}
    </div>
</header>
</div>
