<script>
import {link} from 'svelte-spa-router';
import {displayName, displayDates, displayPhoto} from './composer';
import ShortBiography from './ShortBiography.svelte';
import ResourceCount from '../resource/Count.svelte';
import MoreLink from '../layout/component/MoreLink.svelte'
import Loading from '../layout/component/Loading.svelte';
import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSERS} from './graphql';

const client = getClient();
const composers = query(client, {query: GET_COMPOSERS});
</script>

{#await $composers}
    <Loading />
{:then {data: {composers}}}
    {#each composers as composer}
        <div class="flex sm:block
                    bg-white rounded-lg
                    max-w-full mb-4
                    md:mx-auto max-w-md md:max-w-6xl">

            <div class="flex items-start px-4 py-6">
                <div>
                    <img class="w-12 h-12 rounded-full object-cover mr-4 shadow"
                         src={displayPhoto(composer.photo)} alt={composer.name}>
                </div>

                <div class="w-full relative">
                    <div class="sm:flex w-full items-center justify-between">
                        <h2 class="text-lg font-semibold text-gray-900 -mt-1">
                            <a href="/compositor/{composer.id}" use:link>{displayName(composer)}</a>
                            <span class="text-sm block font-normal text-gray-700">
                                {displayDates(composer.birth, composer.death)}
                            </span>
                        </h2>

                        <MoreLink url="/compositor/{composer.id}" name={displayName(composer)} />
                    </div>

                    <ShortBiography biography={composer.intro || ''} />

                    <ResourceCount composer={composer} />
                </div>
            </div>
        </div>
    {/each}
{:catch error}
    Error: {error}
{/await}
