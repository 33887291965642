<script>
import SectionName from '../../layout/component/SectionName.svelte';

export let name = '';
export let resources = [];
export let size = 'w-56';
</script>

<SectionName {name} />

<div class="sm:grid sm:grid-cols-2 gap-4">
    {#each resources as resource}
        <div class="p-4 mb-4">
            <div class="mb-4 text-blue-800">{resource.name}</div>

            <img src={resource.image.url} alt={resource.name}
                 class="shadow-lg {size} rounded-lg mt-4 mb-4" />

            <div>{@html resource.description}</div>
        </div>
    {/each}
</div>