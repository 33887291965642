<script>
import BackLink from '../layout/component/BackLink.svelte';
import NoResource from './NoResource.svelte';

import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSER} from '../composer/graphql';
import {displayName, displayPhoto} from '../composer/composer';
import ResourceImage from "../layout/component/ResourceImage.svelte";
import Loading from '../layout/component/Loading.svelte';

export let params = {}

const id = params.id;
const client = getClient();
const composer = query(client, {query: GET_COMPOSER, variables: {id}});
</script>

{#await $composer}
    <Loading />
{:then {data: {composer}}}
    <div class="container overflow-hidden mx-auto p-4
                bg-white rounded-lg mx-4">
        <BackLink />

        <div class="pt-4">
            <h1 class="text-center bold text-2xl md:text-3xl">Fots de {displayName(composer)}</h1>
        </div>

        {#if composer.images.length}
            <div class="flex flex-wrap">
                <ResourceImage resources={composer.images} size="w-full"/>
            </div>
        {:else}
            <NoResource name={displayName(composer)} resource="obras" />
        {/if}
    </div>
{:catch error}
    Error: {error}
{/await}