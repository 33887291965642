<script>
import SectionName from '../../layout/component/SectionName.svelte';

export let name = '';
export let resources = [];
</script>

<SectionName {name} />

<ol class="list-disc p-4">
    {#each resources as resource}
        <li>
            <a href={resource.url} target={resource.name}>{resource.name}</a>

            {#if resource.description}
                <div class="py-2">{@html resource.description}</div>
            {/if}
        </li>
    {/each}
</ol>