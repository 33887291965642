<script>
import YouTubeVideo from '../layout/component/YouTubeVideo.svelte';
import BackLink from '../layout/component/BackLink.svelte';
import Loading from '../layout/component/Loading.svelte';
import {getClient, query} from 'svelte-apollo';
import {GET_COMPOSER} from '../composer/graphql';
import {displayName, displayPhoto} from '../composer/composer';

export let params = {}

const id = params.id;
const client = getClient();
const composer = query(client, {query: GET_COMPOSER, variables: {id}});

function composerVideos(composer) {
    const all = [];

    if (composer.works.length) {
        for (let w = 0; w < composer.works.length; ++w) {
            for (let v = 0; v < composer.works[w].videos.length; ++v) {
                all.push(composer.works[w].videos[v]);
            }
        }
    }

    for (let v = 0; v < composer.videos.length; ++v) {
        all.push(composer.videos[v]);
    }

    return all;
}
</script>


{#await $composer}
    <Loading />
{:then {data: {composer}}}
    <div class="container overflow-hidden mx-auto p-4
                bg-white rounded-lg mx-4">
        <BackLink />

        <div class="pt-4">
            <h1 class="text-center font-bold mb-4 text-2xl md:text-3xl">Videos de {displayName(composer)}</h1>
        </div>

        {#each composerVideos(composer) as video}
                <YouTubeVideo name={video.name} url={video.url}
                              description={video.description || ''} />
        {/each}
    </div>
{:catch error}
    Error: {error}
{/await}