let PROTOCOL = 'http';
let HOSTNAME = 'localhost';
let GQL_HOSTNAME = 'localhost:1337';

export const IS_PRODUCTION = window.location.hostname === 'www.seyer-reivaj.com'
    || window.location.hostname === 'seyer-reivaj.com'
    || window.location.hostname === 'xn--compositores-hondureos-3ec.com'
    || window.location.hostname === 'www.xn--compositores-hondureos-3ec.com'
    || window.location.hostname === 'www.compositores-hondureños.com'
    || window.location.hostname === 'compositores-hondureños.com';

if (IS_PRODUCTION) {
    PROTOCOL += 's';
    HOSTNAME = window.location.hostname;
    GQL_HOSTNAME = 'www.seyer-reivaj.com';
}

export const GRAPHQL_URL = `${PROTOCOL}://${GQL_HOSTNAME}/graphql`;

export const USA_DATE = 'MM/DD/YYYY';
export const STD_DATE = 'DD/MM/YYYY';
