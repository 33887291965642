<script>
export let width = 320;
export let height = 180;
export let name;
export let description;
export let url;

/**
 * To extract the id from the URL
 *
 * The admin interface has a regex that requires the user to enter
 * the full URL video to insure they copy paste from the YouTube
 * share link.
 *
 * @param url
 * @returns {*}
 */
const getYouTubeVideoId = url => {
    const id = url.match(/https:\/\/youtu\.be\/([\w\-&\_?=]{11})/);
    return id instanceof Array ? id[1] : false;
}
</script>

{#if getYouTubeVideoId(url)}
    <div class="inline-block align-top w-full mt-4 sm:w-1/2 lg:w-1/3 text-center">
        <iframe class="mx-auto p-4"
                autoplay="0" allowfullscreen="true"
                title={name}  width={width} height={height}
                src="https://www.youtube.com/embed/{getYouTubeVideoId(url)}"></iframe>

        {#if name}
            <span class="text-blue-800">{name}</span>
        {/if}

        {#if description}
            <div class="text-left px-4 pb-4">{@html description}</div>
        {/if}
    </div>
{/if}
